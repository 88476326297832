<template>
  <!-- {{ selectedSetting }} -->
  <div
    class="row gap-11 justify-content-center justify-content-md-start"
    style="--bs-gutter-y: 0rem; --bs-gutter-x: 0rem"
    v-if="currentTab == 'gallery'"
  >
    <div
      class="col-sm-12 col-md-6 col-lg- col-xl-4 rounded h-310px h-md-275px h-lg-250px w-md-350px w-lg-275px w-xxl-400px card-width bg-blue"
      v-for="(template, index) in getTemplate"
      :key="index"
      :class="selected_template == template.id ? 't-selected-outline' : ''"
    >
    <!-- {{ template.id }} -->
      <div
        class="card-width-overlay d-flex align-items-center justify-content-center"
      >
        <div class="select-button" @click="setTemplate(template)">Select</div>
        <div class="preview-button" @click="previewTemplate(template)">
          Preview
        </div>
      </div>
      <div
          class="mini-preview-wrapper "
          style="width: 100%; height: 100%"
          :ref="`selectedTemplateRef`"
      >
        <div
            class="mini-preview-frame mini-preview-frame-activate tw-bg-[#ffffff]"
        >
          <TemplateViewer
            v-if="questions.length > 0 && (template.dynamic_template && template.dynamic_template.base)"
            :dynamic_template="template.dynamic_template"
            :template_css_data="getTemplateCss(template)"
            :questions_data="questions"
            :quiz_data="quizData"
            :is_preview_mode="true"
          />
        </div>
      </div>
      <!-- <img :src="`${assetUrl}/${template.thumbnail_image}`" alt="" class="image-sizing rounded" /> -->
    </div>
  </div>

  <div class="row editor-container" v-if="currentTab == 'editor'">
    <div class="col-xl-9">
      <div class="editor-area">
        <div class="box editor-view p-0">
          <!-- <img :src="theme.thumbnail_image" alt="" width="900" height="650"> -->
          <TemplateViewer
          v-if="questions.length > 0"
            :dynamic_template="dynamicTemplate"
            :template_css_data="templateCssData"
            :questions_data="questions"
            :quiz_data="quizData"
          />
        </div>
      </div>
    </div>
    <div class="col-xl-3">
      <TemplateSetting
        :quiz_data="quizData"
        :template_css="templateCss"
        v-if="templateCss && selectedSetting == 'main_setting'"
        @updateQuizTemplateCss="updateQuizTemplateCss"
      />
      <TextSetting
        v-if="templateCss && selectedSetting == 'text_setting'"
        :template_css="templateCss"
        :editor_type="editorType"
        @updateQuizTemplateCss="updateQuizTemplateCss"
      />
      <FormSetting
        v-if="templateCss && selectedSetting == 'form_setting'"
        :template_css="templateCss"
        @updateQuizTemplateCss="updateQuizTemplateCss"
      />
      <buttonSetting
        v-if="templateCss && selectedSetting == 'button_setting'"
        :template_css="templateCss"
        :quiz_data="quizData"
        @updateQuizTemplateCss="updateQuizTemplateCss"
      />
      <VideoLibrary
        v-if="selectedSetting == 'video_setting'"
        :quiz_data="quizData"
        :questions_data="questions"
        @updateQuizTemplateCss="updateQuizTemplateCss"
      />
      <ImageLibrary
        v-if="selectedSetting == 'image_setting'"
        :quiz_data="quizData"
        :questions_data="questions"
        @updateQuizTemplateCss="updateQuizTemplateCss"
      />
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { mapActions, mapGetters } from "vuex";
import { useToast } from "vue-toastification";
import TemplateViewer from "./quiz-templates/TemplateViewer.vue";
import TemplateSetting from "./quiz-templates/TemplateSetting.vue";
import TextSetting from "./quiz-templates/TextSetting.vue";
import FormSetting from "./quiz-templates/FormSetting.vue";
import buttonSetting from "./quiz-templates/buttonSetting.vue";
import VideoLibrary from "./quiz-templates/VideoLibrary.vue";
import ImageLibrary from "./quiz-templates/ImageLibrary.vue";

export default defineComponent({
  components: {
    TemplateViewer,
    TemplateSetting,
    TextSetting,
    FormSetting,
    buttonSetting,
    VideoLibrary,
    ImageLibrary
  },
  props: {
    quizData: {
      type: Object,
    },
    current_tab: {
      type: String,
    },
  },
  setup() {
    const toast = useToast();
    return { toast };
  },
  data() {
    return {
      selected_template: this.quizData.template,
      loader: false,
      selected_file: "",
      url: "",
      currentTab: null,
      tabIndex: 0,
      dynamicTemplate: null,
      quizTemplate: null,
      templateCssData: [],
      assetUrl : process.env.VUE_APP_ENVIROMENT != 'local' ? process.env.VUE_APP_API_URL : "http://127.0.0.1:8000",
      questions : []
    };
  },
  watch: {
    quizData() {
      this.selected_template = this.quizData.template;
    },
  },
  computed: {
    ...mapGetters({
      getTemplate: "Quiz/getTemplate",
      selectedSetting: "Quiz/getSelectedSetting",
      editorType: "Quiz/getEditorType",
      getQuestions: "Quiz/getQuestions",
    }),
    // questions() {
    //   return this.getQuestions && this.getQuestions.result ? this.getQuestions.result : []
    // },
    templateCss() {
      if (this.selectedSetting == "main_setting") {
        return this.templateCssData.find((css) => css.type == "main");
      } else if (this.selectedSetting == "text_setting") {
        return this.templateCssData.find((css) => css.type == this.editorType);
      } else if (this.selectedSetting == "form_setting") {
        return this.templateCssData.find((css) => css.type == "form");
      } else if (this.selectedSetting == "button_setting") {
        return this.templateCssData.find((css) => css.type == "button");
      }
      return null;
    },
  },
  methods: {
    ...mapActions({
      getallTemplate: "Quiz/getallTemplate",
      getQuizTemplate: "Quiz/getQuizTemplate",
      saveTheme: "Quiz/saveTheme",
      updateTemplate: "Quiz/updateTemplateId",
      setQuestion: "Quiz/setQuestions",
      updateQuizTemplateCssDb: "Quiz/updateQuizTemplateCss",
    }),
    setQuizTemplateCss() {
      let data = {
        quiz_id: this.quizData.id,
        template_id: this.selected_template,
      };
      this.getQuizTemplate(data).then((res) => {
        if (res.data.status == true) {
          let quizTemplateCss = res.data.data.quiz_template_css;
          this.templateCssData = quizTemplateCss;
          this.currentTab = "editor";
        }
      });
    },
    updateView(type) {
      if(type == 'editor'){
        this.setDefaultTemplate()
      }else{
        this.currentTab = type;
      }
    },
    previewTemplate(template) {
      console.log("templ", template);
    },
    getallQuestion() {
      let data = {
        type: "all",
        quiz_id: this.quizData.id,
      };
      this.setQuestion(data).then((res)=>{
        // this.showloader=false
        if(res.data.status==true){
          this.showhiddencheck=res.data.data.hidden_count
          let result=res.data.data.result
              this.questions=result
          }
      }).catch(()=>{
        // this.showloader=false
      })
    },
    updateQuizTemplateCss(type) {
      var cssData = this.templateCssData.find((css) => css.type == type);
      this.updateQuizTemplateCssDb(cssData);
    },
    setTemplate(template) {
      // if (template.id == 7 || template.id == 9 || template.id == 10 || template.id == 11) {
        this.selected_template = template.id;
        this.dynamicTemplate = template.dynamic_template;
        this.setQuizTemplateCss();
        this.updateTemplate(template.id);
      // }
    },
    setDefaultTemplate(){
      this.selected_template = this.quizData.template;
      var selectedTemplate = this.getTemplate.find((template) => template.id == this.selected_template);
      if(selectedTemplate){
        this.dynamicTemplate = selectedTemplate.dynamic_template
        this.setQuizTemplateCss();
        this.updateTemplate(this.selected_template);
      }
     
    },
    setupData(){
      if(this.$route.query && this.$route.query.mode == "editor"){
        this.setDefaultTemplate()
      }
    },
    getTemplateCss(template){
      if(template && this.quizData && this.quizData.quiz_template_csses.length > 0){
        var quizTemplateCss = this.quizData.quiz_template_csses.filter((css)=> css.template_id == template.id)
        if(quizTemplateCss.length > 0)
          return this.quizData.quiz_template_csses.filter((css)=> css.template_id == template.id)
        else
          return template.template_css
      }else{
          return template.template_css
      }
    }
  },
  async created() {
    if (!this.getTemplate.length) {
      await this.getallTemplate();
    } 
    await this.getallQuestion();
  },
  mounted(){
    if(this.$route.query && this.$route.query.mode == "gallery"){
      this.currentTab = 'gallery'
    }
  }
});
</script>
<style>
.border-box {
  border: 1px solid #eff2f5;
  border-radius: 0.85rem;
}
.mini-preview-frame > div> * {
    position: absolute;
    top: 0;
    /* min-height: calc(100vh - -500px) !important; */
    right: 0;
    left: 0;
    bottom: 0;
}
</style>
<style scoped lang="scss">
.quiz-heading {
  font-size: 35px !important;
}
.card-width {
  width: 504px;
  position: relative;
  height: 300px;
  cursor: pointer;
  overflow: hidden;
  background: transparent;
  z-index: 1;
}
.t-selected-outline {
  outline: 2px solid #2477ff;
}
.card-width-overlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 100;
  opacity: 0;
  -webkit-transition: z-index 0.3s steps(1), opacity 0.3s, margin-top 0.3s;
  transition: z-index 0.3s steps(1), opacity 0.3s, margin-top 0.3s;
}

.card-width-overlay:hover {
  background: rgba(0, 0, 0, 0.5);

  opacity: 1;
}

.image-sizing {
  object-fit: fill;
  object-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  image-rendering: -webkit-optimize-contrast;
}
.preview-button,
.select-button {
  width: 95px;
  font-size: 14px;
  color: #fff;
  text-align: center;
  font-weight: 600;
  line-height: 40px;
  cursor: pointer;
}
.select-button {
  background-color: #2477ff;
  border-radius: 5px;
}
.preview-button {
  border-radius: 5px;
  border: 1px solid #fff;
  margin-left: 15px;
}

@media (min-width: 200px) and (max-width: 1200px) {
  .quiz-heading {
    font-size: 28px !important;
  }
  .quiz-gallary {
    justify-content: center !important;
  }
  .card-width {
    width: 400px;
  }
}
@media (max-width: 1440px) {
  .quiz-heading {
    font-size: 28px !important;
  }
  .quiz-gallary {
    align-items: center;
  }
  .card-width {
    width: 400px;
  }
}
@media (min-width: 1441px) {
  .card-width {
    width: 504px !important;
    height: 300px !important;
  }
}

.editor-container {
  color: white;
  min-height: 760px;
}

.editor-area {
  // margin-right: 15px;
  height: 100%;
}

.editor-view {
  //   display: flex;
  //   align-items: center;
  //   justify-content: center;
  // //   height: 91.35%;
  //   height: 100%;
}

.mini-preview-frame {
  border: none;
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
  padding: 0 !important;
  -webkit-transform: scale(0.25) !important;
  transform: scale(0.25) !important;
  pointer-events: none;
  width: 400%;
  height: 400% !important;
  overflow: hidden;
  border-radius: 30px;
}

.mini-preview-frame div {
  height: 100%;
}

.mini-preview-frame div {
  height: 100%;
  margin-top: 0px !important;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  transform: scale(1.7) !important;
  /* pointer-events: none !important; */
}
</style>
