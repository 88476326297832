<!-- eslint-disable vue/no-deprecated-slot-attribute -->
<template>
    <div class="box p-0">
        <div class="custom-editor-tab">
        <svg
            width="24" height="24"
            viewBox="0 0 19 17" :fill="tabIndex === 0 ? '#2477FF' : '#A7A8AB'"
            xmlns="http://www.w3.org/2000/svg"
            class="cursor-pointer"
            @click="tabIndex = 0"
        >
        <path
            d="M17 10C17 10 15 12.9062 15 14C15 15.125 15.875 16 17 16C18.0938 16 19 15.125 19 14C19 12.9062 17 10 17 10ZM16.6875 6.8125L10.1875 0.3125C10.0312 0.125 9.78125 0 9.5 0C9.25 0 8.96875 0.125 8.78125 0.3125L6.0625 3.03125L3.1875 0.15625C3 -0.03125 2.6875 -0.03125 2.46875 0.15625L2.125 0.5C1.9375 0.71875 1.9375 1.03125 2.125 1.21875L5 4.09375L1.875 7.21875C0.6875 8.40625 0.6875 10.3125 1.875 11.4688L5.53125 15.125C6.125 15.7188 6.875 16.0312 7.65625 16.0312C8.40625 16.0312 9.1875 15.7188 9.78125 15.125L16.6875 8.21875C17.0938 7.8125 17.0938 7.1875 16.6875 6.8125ZM8.71875 14.0625C8.4375 14.375 8.0625 14.5 7.65625 14.5C7.25 14.5 6.875 14.375 6.59375 14.0625L3.03125 10.5H12.2812L8.71875 14.0625ZM13.7812 9H2.53125C2.59375 8.75 2.71875 8.5 2.9375 8.28125L6.0625 5.15625L8.25 7.34375C8.53125 7.625 9 7.625 9.3125 7.34375C9.59375 7.03125 9.59375 6.5625 9.3125 6.28125L7.125 4.09375L9.5 1.71875L15.2812 7.5L13.7812 9Z"/>
        </svg>
        <svg xmlns="http://www.w3.org/2000/svg" :fill="tabIndex === 1 ? '#2477FF' : '#A7A8AB'" @click="tabIndex = 1" width="24" height="24" viewBox="0 0 16 16">
            <path d="M 2.5 2 C 1.6774686 2 1 2.6774686 1 3.5 L 1 10.5 C 1 11.322531 1.6774686 12 2.5 12 L 3 12 L 3 12.5 C 3 13.322531 3.6774686 14 4.5 14 L 13.5 14 C 14.322531 14 15 13.322531 15 12.5 L 15 5.5 C 15 4.6774686 14.322531 4 13.5 4 L 13 4 L 13 3.5 C 13 2.6774686 12.322531 2 11.5 2 L 2.5 2 z M 2.5 3 L 11.5 3 C 11.781469 3 12 3.2185314 12 3.5 L 12 8.7675781 L 9.4882812 6.0273438 L 7.5136719 8.3261719 L 4.4882812 5.0273438 L 2 7.9238281 L 2 3.5 C 2 3.2185314 2.2185314 3 2.5 3 z M 9.5 4 A 0.5 0.5 0 0 0 9 4.5 A 0.5 0.5 0 0 0 9.5 5 A 0.5 0.5 0 0 0 10 4.5 A 0.5 0.5 0 0 0 9.5 4 z M 13 5 L 13.5 5 C 13.781469 5 14 5.2185314 14 5.5 L 14 12.5 C 14 12.781469 13.781469 13 13.5 13 L 4.5 13 C 4.2185314 13 4 12.781469 4 12.5 L 4 12 L 11.5 12 C 12.322531 12 13 11.322531 13 10.5 L 13 5 z M 4.5117188 6.5351562 L 8.6054688 11 L 2.5 11 C 2.2185314 11 2 10.781469 2 10.5 L 2 9.4589844 L 4.5117188 6.5351562 z M 9.5117188 7.5351562 L 12 10.25 L 12 10.5 C 12 10.781469 11.781469 11 11.5 11 L 9.9628906 11 L 8.1933594 9.0683594 L 9.5117188 7.5351562 z" font-weight="400" font-family="sans-serif" white-space="normal" overflow="visible"/>
        </svg>
        <svg
            xmlns="http://www.w3.org/2000/svg"
            :fill="tabIndex === 2 ? '#2477FF' : '#A7A8AB'"
            viewBox="0 0 50 50"
            width="24" height="24"
            class="cursor-pointer"
            @click="tabIndex = 2"
        >
        <path
            d="M47.16,21.221l-5.91-0.966c-0.346-1.186-0.819-2.326-1.411-3.405l3.45-4.917c0.279-0.397,0.231-0.938-0.112-1.282 l-3.889-3.887c-0.347-0.346-0.893-0.391-1.291-0.104l-4.843,3.481c-1.089-0.602-2.239-1.08-3.432-1.427l-1.031-5.886 C28.607,2.35,28.192,2,27.706,2h-5.5c-0.49,0-0.908,0.355-0.987,0.839l-0.956,5.854c-1.2,0.345-2.352,0.818-3.437,1.412l-4.83-3.45 c-0.399-0.285-0.942-0.239-1.289,0.106L6.82,10.648c-0.343,0.343-0.391,0.883-0.112,1.28l3.399,4.863 c-0.605,1.095-1.087,2.254-1.438,3.46l-5.831,0.971c-0.482,0.08-0.836,0.498-0.836,0.986v5.5c0,0.485,0.348,0.9,0.825,0.985 l5.831,1.034c0.349,1.203,0.831,2.362,1.438,3.46l-3.441,4.813c-0.284,0.397-0.239,0.942,0.106,1.289l3.888,3.891 c0.343,0.343,0.884,0.391,1.281,0.112l4.87-3.411c1.093,0.601,2.248,1.078,3.445,1.424l0.976,5.861C21.3,47.647,21.717,48,22.206,48 h5.5c0.485,0,0.9-0.348,0.984-0.825l1.045-5.89c1.199-0.353,2.348-0.833,3.43-1.435l4.905,3.441 c0.398,0.281,0.938,0.232,1.282-0.111l3.888-3.891c0.346-0.347,0.391-0.894,0.104-1.292l-3.498-4.857 c0.593-1.08,1.064-2.222,1.407-3.408l5.918-1.039c0.479-0.084,0.827-0.5,0.827-0.985v-5.5C47.999,21.718,47.644,21.3,47.16,21.221z M25,32c-3.866,0-7-3.134-7-7c0-3.866,3.134-7,7-7s7,3.134,7,7C32,28.866,28.866,32,25,32z"/>
        </svg>
<!-- 
        <svg xmlns="http://www.w3.org/2000/svg" :fill="tabIndex === 3 ? '#2477FF' : '#A7A8AB'" @click="tabIndex = 3" class="cursor-pointer" width="22" height="22" viewBox="0 0 512 512">
          <path d="M256 48a208 208 0 1 1 0 416 208 208 0 1 1 0-416zm0 464A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM199.4 312.6c-31.2-31.2-31.2-81.9 0-113.1s81.9-31.2 113.1 0c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9c-50-50-131-50-181 0s-50 131 0 181s131 50 181 0c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0c-31.2 31.2-81.9 31.2-113.1 0z"/>
        </svg> -->
        </div>
        <div class="custom-editor-tab-body">
            <div v-if="tabIndex === 0">
                <div class="d-flex justify-content-between align-items-center">
                    <h3 class="mb-7 h3">Main Theme</h3>
                    <button class="btn btn-bg-light btn-sm btn-active-color-primary me-1 suffle-button" @click="shuffleTemplateSize">Shuffle</button>
                </div>
               
                <div id="background_div">
                    <div class="d-flex justify-content-between">
                        <div class="d-flex mt-5">
                            <span class="form-check form-check-custom form-check-solid">
                                <input class="form-check-input w-20px h-20px" type="radio" name="bg_type" id="bg_color_status" @change="updateQuizTemplateCssData" v-model="templateCss.bg_type" value="color">
                                <label class="form-label m-0 ms-4 me-4" for="bg_color_status">
                                    Color
                                </label>
                            </span>

                            <span class="form-check form-check-custom form-check-solid">
                                <input class="form-check-input w-20px h-20px" type="radio"
                                    name="bg_type" @change="updateQuizTemplateCssData" v-model="templateCss.bg_type" id="bg_image_status" value="image">
                                <label class="form-label m-0 ms-4 me-4" for="bg_image_status">
                                    Image
                                </label>
                            </span>
                            
                            <span class="form-check form-check-custom form-check-solid">
                                <input class="form-check-input w-20px h-20px" type="radio"
                                    name="bg_type" @change="updateQuizTemplateCssData" v-model="templateCss.bg_type" id="bg_gradient_status" value="gradient">
                                <label class="form-label m-0 ms-4 me-4" for="bg_gradient_status">
                                    Gradient
                                </label>
                            </span>
                        </div>
                    </div>
                    <div class="row mt-7">
                        <div class="col-lg-12" v-if="templateCss.bg_type=='color'" id="bg_color_div">
                            <div class="mb-3 mt-5" v-click-outside="closeBgColorPicker">
                                <div class="input-group mb-3" >
                                    <input type="text" class="form-control form-control-solid" @input="updateColor('bg')" v-model="bgColor.hex8">
                                    <div class="input-group-append" @click="isBgColorPicker = !isBgColorPicker">
                                        <span class="input-group-text" id="basic-addon2">
                                            <div class="bg-white">
                                                <div class="rect" :style="{ backgroundColor: bgColor.hex8 }"></div>
                                            </div>
                                        </span>
                                    </div>
                                </div>
                                <Sketch
                                    :class="{ colorpicker: true, 'd-none': !isBgColorPicker }"
                                    v-model="bgColor"
                                    :preset-colors="['#ffffff','#efefef','#000000','#201652','#0075B2','#FFE300','#42B99F','#CC0000']"
                                ></Sketch>
                            </div>
                        </div>

                        <div class="col-lg-12" v-if="templateCss.bg_type=='image'" id="bg_image_div">
                            <div class="mb-10 mt-5 fv-row fv-plugins-icon-container">
                                <label for="" class="form-label">Image Position</label>
                                <select
                                class="form-select form-select-solid" @change="updateQuizTemplateCssData" v-model="templateCss.image_position"
                                >
                                <option
                                    v-for="(position, index) in imagePositions"
                                    :key="index"
                                    :value="position.value"
                                >
                                    {{ position.name }}
                                </option>
                                </select>
                            </div>

                            <div class="row">
                                <div class="col-md-6 col-xs-12 col-sm-12">
                                    <input type="file" class="d-none" ref="fullImageInput" @change="onFileChange($event,'bg')">
                                        <div class="image-block">
                                        <div class="image-block-edit-icon" @click="$refs.fullImageInput.click()">
                                            <i class="fa fa-pen" aria-hidden="true"></i>
                                        </div>
                                        <img
                                            class="w-100 h-100 image-radious"
                                            v-if="uploadedImage"
                                            :src="uploadedImageUrl"
                                        >
                                        <img
                                            class="w-100 h-100 image-radious"
                                            v-else :src="imageSrc"
                                        >
                                    </div>
                                </div>
                                <div class="col-md-6 col-xs-12 col-sm-12">
                                    <label for="" class="form-label">Color Overlay</label>
                                    <div class="input-group mb-3" v-click-outside="closeOverlayColorPicker">
                                        <input type="text" class="form-control form-control-solid"  @input="updateColor('overlay')" v-model="overlayColor.hex8">
                                        <div class="input-group-append" @click="isOverlayColorPicker = !isOverlayColorPicker">
                                            <span class="input-group-text" id="basic-addon2">
                                                <div class="bg-white">
                                                    <div class="rect" :style="{ backgroundColor: overlayColor.hex8 }"></div>
                                                </div>
                                            </span>
                                        </div>
                                        <Sketch
                                            :class="{ colorpicker: true, 'd-none': !isOverlayColorPicker }"
                                            v-model="overlayColor"
                                            :preset-colors="['#ffffff','#efefef','#000000','#201652','#0075B2','#FFE300','#42B99F','#CC0000']"
                                        ></Sketch>
                                    </div>
                                </div>

                             
                            </div>
                        </div>

                        <div class="col-lg-12" v-if="templateCss.bg_type=='gradient'" id="bg_gradient_div">
                            <div class="row mt-5">
                                <div class="col-md-6 col-xs-12 col-sm-12">
                                    <label for="" class="form-label">Gradient Color</label>
                                    <div class="input-group mb-3" v-click-outside="closeGradient1ColorPicker">
                                        <input type="text" class="form-control form-control-solid" @input="updateColor('gradient1')" v-model="gradientColor1.hex8">
                                        <div class="input-group-append" @click="isGradient1ColorPicker = !isGradient1ColorPicker">
                                            <span class="input-group-text" id="basic-addon2">
                                                <div class="bg-white">
                                                    <div class="rect" :style="{ backgroundColor: gradientColor1.hex8 }"></div>
                                                </div>
                                            </span>
                                        </div>
                                        <Sketch
                                            :class="{ colorpicker: true, 'd-none': !isGradient1ColorPicker }"
                                            v-model="gradientColor1"
                                            :preset-colors="['#ffffff','#efefef','#000000','#201652','#0075B2','#FFE300','#42B99F','#CC0000']"
                                        ></Sketch>
                                    </div>
                                </div>  

                                <div class="col-md-6 col-xs-12 col-sm-12">
                                    <label for="" class="form-label">Stop 1</label>
                                    <div class="row">
                                        <div class="col-9 d-flex align-items-center">
                                            <input class="form-range" type="range" @change="updateQuizTemplateCssData" v-model="templateCss.gradient_color_stop_1" min="0" max="100" />
                                        </div>
                                        <div class="col-3 ps-0">
                                            <div class="custom-size">{{templateCss.gradient_color_stop_1 }} </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-5">
                                <div class="col-md-6 col-xs-12 col-sm-12">
                                    <label for="" class="form-label">Gradient Color</label>
                                    <div class="input-group mb-3" v-click-outside="closeGradient2ColorPicker">
                                        <input type="text" class="form-control form-control-solid" @input="updateColor('gradient2')" v-model="gradientColor2.hex8">
                                        <div class="input-group-append" @click="isGradient2ColorPicker = !isGradient2ColorPicker">
                                            <span class="input-group-text" id="basic-addon2">
                                                <div class="bg-white">
                                                    <div class="rect" :style="{ backgroundColor: gradientColor2.hex8 }"></div>
                                                </div>
                                            </span>
                                        </div>
                                        <Sketch
                                            :class="{ colorpicker: true, 'd-none': !isGradient2ColorPicker }"
                                            v-model="gradientColor2"
                                            :preset-colors="['#ffffff','#efefef','#000000','#201652','#0075B2','#FFE300','#42B99F','#CC0000']"
                                        ></Sketch>
                                    </div>
                                </div>  

                                <div class="col-md-6 col-xs-12 col-sm-12">
                                    <label for="" class="form-label">Stop 2</label>
                                    <div class="row">
                                        <div class="col-9 d-flex align-items-center">
                                            <input class="form-range" type="range" @change="updateQuizTemplateCssData" v-model="templateCss.gradient_color_stop_2" min="0" max="100" />
                                        </div>
                                        <div class="col-3 ps-0">
                                            <div class="custom-size">{{templateCss.gradient_color_stop_2 }} </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="row mt-5">
                                <div class="col-md-12 col-xs-12 col-sm-12">
                                    <label for="" class="form-label">Angle</label>
                                    <div class="row">
                                        <div class="col-9 d-flex align-items-center">
                                            <input class="form-range" type="range" @change="updateQuizTemplateCssData" v-model="templateCss.gradient_angle" min="0" max="360" />
                                        </div>
                                        <div class="col-3">
                                            <div class="custom-size">{{ templateCss.gradient_angle ? templateCss.gradient_angle : '0' }} deg</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-12 col-xs-12 col-sm-12">
                            <hr style="background-color: gray" class="my-9"/>
                        </div>

                        <div v-click-outside="closeBoxColorPicker">
                            <label for="" class="form-label">Box Color</label>
                            <div class="input-group mb-3">
                                <input type="text" class="form-control form-control-solid" @input="updateColor('box')" v-model="boxColor.hex8">
                                <div class="input-group-append" @click="isBoxColorPicker = !isBoxColorPicker">
                                    <span class="input-group-text" id="basic-addon2">
                                        <div class="bg-white">
                                            <div class="rect" :style="{ backgroundColor: boxColor.hex8 }"></div>
                                        </div>
                                    </span>
                                </div>
                            </div>
                            <Sketch
                                :class="{ colorpicker: true, 'd-none': !isBoxColorPicker }"
                                v-model="boxColor"
                                :preset-colors="['#ffffff','#efefef','#000000','#201652','#0075B2','#FFE300','#42B99F','#CC0000']"
                            ></Sketch>
                        </div>
                    </div>
                </div>
            </div>

            <div v-if="tabIndex === 1">
                <div class="d-flex justify-content-between align-items-center form-check form-check-solid form-switch fv-row ps-0">
                    <label class="form-check-label h3 text-white" for="logo-bar">Logo Bar</label>
                    <input class="custom-checked form-check-input w-45px h-30px" type="checkbox" @change="updateQuizData" id="logo-bar" v-model="quiz.add_logo_bar">
                </div>
                <hr style="background-color: gray" class="my-7">
                <div class="row">
                    <div class="col-md-6 col-xs-12 col-sm-12">
                        <label for="" class="form-label">Logo Image</label>
                        <input type="file" class="d-none" ref="logoImageInput" @change="onFileChange($event,'logo')">
                            <div class="image-block">
                            <div class="image-block-edit-icon" @click="$refs.logoImageInput.click()">
                                <i class="fa fa-pen" aria-hidden="true"></i>
                            </div>
                            <img
                                class="w-100 h-100 image-radious"
                                v-if="uploadedImage"
                                :src="uploadedImageUrl"
                            >
                            <img
                                class="w-100 h-100 image-radious"
                                v-else :src="logoImageSrc"
                            >
                        </div>
                    </div>
                </div>
            </div>

            <div v-if="tabIndex === 2">
                <h3 class="mb-10 h3">Extra Options</h3>
                <div class="d-flex justify-content-between align-items-center form-check form-check-solid form-switch fv-row ps-0 mt-6">
                    <label class="form-check-label" for="add-intro-page">Add Intro Page</label>
                    <input class="custom-checked form-check-input w-45px h-30px" type="checkbox"  @change="updateQuizData" v-model="quiz.add_intro_page" id="add-intro-page">
                </div>
                <hr style="background-color: gray" class="my-5">
                <div class="d-flex justify-content-between align-items-center form-check form-check-solid form-switch fv-row ps-0 mt-6">
                    <label class="form-check-label" for="add-exit-page">Add Exit Page</label>
                    <input class="custom-checked form-check-input w-45px h-30px" type="checkbox" @change="updateQuizData" id="add-exit-page" v-model="quiz.add_exit_page">
                </div>
                <hr style="background-color: gray" class="my-5">
                <div class="d-flex justify-content-between align-items-center form-check form-check-solid form-switch fv-row ps-0 mt-6">
                    <label class="form-check-label" for="add-progress-page">Add Progress Page</label>
                    <input class="custom-checked form-check-input w-45px h-30px" type="checkbox" @change="updateQuizData" id="add-progress-page" v-model="quiz.add_progress_page">
                </div>
                <hr style="background-color: gray" class="my-5">
                <div class="d-flex justify-content-between align-items-center form-check form-check-solid form-switch fv-row ps-0 mt-6">
                    <label class="form-check-label" for="enable-customizations">Enable Customizations</label>
                    <input class="custom-checked form-check-input w-45px h-30px" type="checkbox" @change="updateQuizData" id="enable-customizations" v-model="quiz.is_customization_enable">
                </div>
                <hr style="background-color: gray" class="my-5">
                <div class="d-flex justify-content-between align-items-center form-check form-check-solid form-switch fv-row ps-0 mt-6">
                    <label class="form-check-label" for="progress-bar">Progress Bar</label>
                    <input class="custom-checked form-check-input w-45px h-30px" type="checkbox" @change="updateQuizData" id="progress-bar" v-model="quiz.add_progress_bar">
                </div>
                <hr style="background-color: gray" class="my-5">
                <div class="d-flex justify-content-between align-items-center form-check form-check-solid form-switch fv-row ps-0 mt-6">
                    <label class="form-check-label" for="previous-arrow">Previous Arrow</label>
                    <input class="custom-checked form-check-input w-45px h-30px" type="checkbox" @change="updateQuizData" id="previous-arrow" v-model="quiz.add_previous_arrow">
                </div>
            </div>
        </div>
    </div> 
</template>

<script>
/* eslint-disable */
import {defineComponent,toRefs} from "vue";
import { Sketch } from '@ckpack/vue-color';
import vClickOutside from 'click-outside-vue3'
import {imagePositions} from '@/core/helpers/staticData'
import _ from "lodash";
import { mapActions } from "vuex";
import { useToast } from "vue-toastification";

export default defineComponent({
    directives: {
      clickOutside: vClickOutside.directive
    },
  props:{
    quiz_data:{
      type:Object
    },
    template_css:{
      type:Object
    },
  },
components: {Sketch},
    setup(props) {
        const toast = useToast();
        const {quiz_data: quizData} = toRefs(props);
        const {template_css: templateCss} = toRefs(props);
        return {toast,quizData,templateCss};
    },
    data() {
        return {
            tabIndex : 0,
            bgColor : {},
            isBgColorPicker : false,
            boxColor : {},
            isBoxColorPicker : false,
            overlayColor : {},
            isOverlayColorPicker : false,
            imagePositions: imagePositions,
            uploadedImage: null,
            uploadedImageUrl: null,
            assetUrl : process.env.VUE_APP_ENVIROMENT != 'local' ? process.env.VUE_APP_API_URL : "http://127.0.0.1:8000",
            quiz: {},
            isGradient1ColorPicker : false,
            gradientColor1 : {},
            isGradient2ColorPicker : false,
            gradientColor2 : {},
            logoImage: null,
            logoImageUrl: null,
            apiCallStatus:false
        }
    },
    created() {
        this.setupQuiz()
    },
    computed : {
        imageSrc() {
            if (this.templateCss.bg_image && this.templateCss.bg_image.search('blob:') == -1) {
                return this.assetUrl + '/storage/' + this.templateCss.bg_image
            } else if (this.templateCss.bg_image) {
                return this.templateCss.bg_image
            } else {
                return require('@/assets/images/img/no-image.png')
            }
        },  
        logoImageSrc() {
            if (this.templateCss.logo_image && this.templateCss.logo_image.search('blob:') == -1) {
                return this.assetUrl + '/storage/' + this.templateCss.logo_image
            } else if (this.templateCss.logo_image) {
                return this.templateCss.logo_image
            }else {
                return require('@/assets/images/img/no-image.png')
            }
        },  
    },
    watch: {
        bgColor(){
            this.updateColor('bg')
        },
        boxColor(){
            this.updateColor('box')
        },
        overlayColor(){
            this.updateColor('overlay')
        },
        gradientColor1(){
            this.updateColor('gradient1')
        },
        gradientColor2(){
            this.updateColor('gradient2')
        },
    },
    mounted() {
        this.setupColors()
        this.updateApiCallStatus()
    },
    methods: {
        ...mapActions({
            updateQuizSetting:'Quiz/updateQuizSetting',
            updateTemplateImage:'Quiz/updateTemplateImage',
        }),
        setupColors(){
            if (this.templateCss && this.templateCss.bg_color) {
                let data = {
                    hex8: this.templateCss.bg_color
                }
                this.bgColor = data
            }
            if (this.templateCss && this.templateCss.box_color) {
                let boxData = {
                    hex8: this.templateCss.box_color
                }
                this.boxColor = boxData
            }
            if (this.templateCss && this.templateCss.color_overlay) {
                let overlayData = {
                    hex8: this.templateCss.color_overlay
                }
                this.overlayColor = overlayData
            }
            if (this.templateCss && this.templateCss.gradient_color_1) {
                let gradient1Data = {
                    hex8: this.templateCss.gradient_color_1
                }
                this.gradientColor1 = gradient1Data
            }
            if (this.templateCss && this.templateCss.gradient_color_2) {
                let gradient2Data = {
                    hex8: this.templateCss.gradient_color_2
                }
                this.gradientColor2 = gradient2Data
            }
        },
        closeBgColorPicker(){
            this.isBgColorPicker = false
        },
        closeBoxColorPicker(){
            this.isBoxColorPicker = false
        },
        closeOverlayColorPicker(){
            this.isOverlayColorPicker = false
        },
        closeGradient1ColorPicker(){
            this.isGradient1ColorPicker = false
        },
        closeGradient2ColorPicker(){
            this.isGradient2ColorPicker = false
        },
        updateColor(type){
            if(this.apiCallStatus){
                if (type == 'bg') {
                    this.templateCss.bg_color = this.bgColor.hex8
                } else if (type == 'box') {
                    this.templateCss.box_color = this.boxColor.hex8
                } else if (type == 'overlay') {
                    this.templateCss.color_overlay = this.overlayColor.hex8
                } else if (type == 'gradient1') {
                    this.templateCss.gradient_color_1 = this.gradientColor1.hex8
                } else if (type == 'gradient2') {
                    this.templateCss.gradient_color_2 = this.gradientColor2.hex8
                }
                this.updateQuizTemplateCssData()  
            }
        },
        onFileChange(e,type) {
            const file = e.target.files[0];
            if (file) {
                if (file.type == 'image/png' || file.type == 'image/jpg' || file.type == 'image/jpeg' || file.type == 'image/svg' || file.type == 'image/svg+xml' || file.type == 'image/gif') {
                    if(type == 'bg'){
                        this.uploadedImage = file
                        this.uploadedImageUrl = URL.createObjectURL(file);
                        this.templateCss.bg_image = this.uploadedImageUrl
                    }else if(type == 'logo'){
                        this.logoImage = file
                        this.logoImageUrl = URL.createObjectURL(file);
                        this.templateCss.logo_image = this.logoImageUrl
                    }
                    this.updateBgImage(type)
                } else {
                    this.$refs.fullImageInput.value = ''
                    this.$swal.fire(
                        "Error!",
                        "Please enter valid image file.",
                        "error"
                    );
                }
            }
        },
        updateBgImage(type) {
            var formdata = new FormData();
            formdata.append('quiz_id', this.quizData.id);
            formdata.append('template_id', this.templateCss.template_id);
            formdata.append('type', 'main');

            const MAX_SIZE = 3072 * 1024; // 3072 KB in bytes
            let imageToUpload = null;

            if (type == 'bg') {
                imageToUpload = this.uploadedImage;
                formdata.append('bg_image', this.uploadedImage);
            } else if (type == 'logo') {
                imageToUpload = this.logoImage;
                formdata.append('logo_image', this.logoImage);
            }

            // Check if image size exceeds the limit
            if (imageToUpload && imageToUpload.size > MAX_SIZE) {
                console.log("Image size must be less than 3MB (3072 KB).");
                this.toast.error("Image size must be less than 3MB (3072 KB).");
                return; // Stop execution if validation fails
            }

            this.updateTemplateImage(formdata);
        },
        shuffleTemplateSize(){
            if(this.templateCss && this.templateCss.template_size && this.templateCss.template_size == 3){
                this.templateCss.template_size = 1
            }else{
                if(this.templateCss && this.templateCss.template_size)
                this.templateCss.template_size += 1
            }
            this.updateQuizTemplateCssData()
        },
        setupQuiz(){
            this.quiz = {
                quiz_id : this.quizData.id,
                add_intro_page : !!this.quizData.add_intro_page,
                add_exit_page : !!this.quizData.add_exit_page,
                add_progress_page : !!this.quizData.add_progress_page,
                is_customization_enable : !!this.quizData.is_customization_enable,
                add_progress_bar : !!this.quizData.add_progress_bar,
                add_previous_arrow : !!this.quizData.add_previous_arrow,
                add_logo_bar : !!this.quizData.add_logo_bar,
                add_customizable_button : !!this.quizData.add_customizable_button,
            }
        },
        updateQuizData(){
           this.quizData.add_logo_bar = this.quiz.add_logo_bar
           this.quizData.add_progress_bar = this.quiz.add_progress_bar
           this.quizData.add_previous_arrow = this.quiz.add_previous_arrow
           this.updateQuizDataDB()
        },
        updateQuizDataDB: _.debounce(function () {
            this.updateQuizSetting(this.quiz)
        }, 1000),
        updateQuizTemplateCssData: _.debounce(function () {
            this.$emit('updateQuizTemplateCss','main')
        }, 1000),
        updateApiCallStatus(){
            setTimeout(() => {
                this.apiCallStatus = true
            }, 200);
        }
    },
})
</script>

<style scoped>

.custom-checked{
    width: 40px !important; 
    height: 23px !important;
}
.box {
  background-color: #2D2F3A;
  border-radius: 5px;
  padding: 15px;
}

.custom-editor-tab {
  color: lightgrey;
  padding: 13px;
  display: flex;
  align-items: center;
  gap: 15px;
  justify-content: space-around;
}
.custom-editor-tab-body {
  background-color: #343542;
  height: 93.2%;
  border-radius: 0 0 5px 5px;
  padding: 20px 15px;
  min-height: calc(100vh - 260px);
}
.custom-editor-tab-body h3 {
  color: white;
  font-weight: 400;
}
.custom-tabs {
  color: lightgrey;
  display: flex;
  align-items: center;
  gap: 15px;
}

.custom-tabs .active {
  color: white;
  font-weight: 600;
}

.action-box {
  display: flex;
  align-items: center;
  gap: 40px;
}

.form-check {
  min-height: 0;
}

.vc-input__input{
    color: #202020 !important;
}

.rect{
    width: 23px;
    height: 23px;
}
.input-group-text{
    border: 0;
    background-color: #2D2F3A;
    border-radius: 0 0.475rem 0.475rem 0;
}

.image-block {
  height: 90px;
  border-radius: 6px;
  position: relative;
}

.image-block-edit-icon {
  position: absolute;
  color: #11132d;
  right: 10px;
  background: white;
  font-size: 16px;
  border-radius: 50%;
  height: 25px;
  width: 25px;
  display: flex;
  padding-left: 6px;
  padding-top: 6px;
  top: 7px;
}

.image-radious {
  border-radius: 10px;
}

.bgCover101 {
  background-size: 100% 100% !important;
  -webkit-background-size: 100% 100% !important;
  background-repeat: no-repeat !important;
}

.bgCover {
  background-size: cover !important;
  -webkit-background-size: cover !important;
  background-attachment: fixed !important;
  background-repeat: repeat repeat !important;
  background-position: center center;
}

.bgCover100 {
  background-size: 100% auto !important;
  -webkit-background-size: 100% auto !important;
  background-repeat: no-repeat !important;
}

.bgNoRepeat {
  background-repeat: no-repeat !important;
}

input.vc-input__input{
    color: #202020 !important;
}
.custom-size{
    background-color: #2d2f3a;
    border-radius: 4px;
    padding: 10px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    width: 100%;
}
.form-label{
    color: #e4e6ef;
}
.suffle-button{
    background-color: #3D3F4C;
    color: #e4e6ef;
}
.form-check.form-check-solid .form-check-input {
    border: 0;
    background-color: #3d3f4c;
}
.form-check.form-check-solid .form-check-input:checked {
    background-color: #e49c35;
}
.form-control.form-control-solid, .form-select-solid {
    background-color: #3d3f4c;
    border-color: #3d3f4c;
    color: #c4c7cc;
    transition: color .2s ease,background-color .2s ease;
}
.dropdown.show > .form-select.form-select-solid, .form-select.form-select-solid:active, .form-select.form-select-solid.active, .form-select.form-select-solid:focus, .form-select.form-select-solid.focus {
    background-color: #3d3f4c;
    border-color: #3d3f4c;
    color: #c4c7cc;
    transition: color 0.2s ease, background-color 0.2s ease;
}

.vc-sketch{
    position: absolute;
    z-index: 10000;
}
</style>