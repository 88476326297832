<!-- eslint-disable vue/no-deprecated-slot-attribute -->
<template>
    <div class="box p-0">
        <div class="custom-editor-tab">
            <i class="fa fa-video fa-lg text-primary"></i>
        </div>
        <div class="custom-editor-tab-body">
            <div>
                <div class="d-flex justify-content-between align-items-center">
                    <h3 class="mb-7 h3">Video Library</h3>
                </div>
                <!-- <pre>{{ library }}</pre> -->
                <div class="row">
                    <div class="col-12">
                        <div class="mb-5">
                            <span for="video_duration" class="form-label fw-bolder">Search Clips</span>
                            <div class="d-flex">
                                <input
                                    id="video_duration"
                                    type="text"
                                    class="form-control form-control-solid form-control-sm me-3"
                                    v-model="filter.query"
                                    @keyup.enter="searchVideoDebounce"
                                />
                                <button class="btn btn-icon btn-custom btn-active-color-primary btn-sm" @click="searchVideoDebounce">Go</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div
                        class="col-sm-12 col-md-6 mb-7 video-box"
                        v-for="(video, index) in getVideosData"
                        :key="index"
                        @click="updateVideoData(video)"
                    >
                        <video class="aspect-video block object-cover rounded cursor-pointer" :class="library.video_id && video.id == library.video_id ? 'active' : ''" loop muted :ref="`video_${video.id}`" @mouseover="playVideo(video)" @mouseleave="stopVideo(video)">
                            <source :src="getHqVideo(video.video_files)" type="video/mp4">
                        </video>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <a class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-4" v-if="filter.page > 1" @click="previousPage">
                            <span class="svg-icon svg-icon-3 cursor-pointer">
                                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                        <polygon points="0 0 24 0 24 24 0 24"/>
                                        <path d="M5.29288961,6.70710318 C4.90236532,6.31657888 4.90236532,5.68341391 5.29288961,5.29288961 C5.68341391,4.90236532 6.31657888,4.90236532 6.70710318,5.29288961 L12.7071032,11.2928896 C13.0856821,11.6714686 13.0989277,12.281055 12.7371505,12.675721 L7.23715054,18.675721 C6.86395813,19.08284 6.23139076,19.1103429 5.82427177,18.7371505 C5.41715278,18.3639581 5.38964985,17.7313908 5.76284226,17.3242718 L10.6158586,12.0300721 L5.29288961,6.70710318 Z" fill="#000000" fill-rule="nonzero" transform="translate(8.999997, 11.999999) scale(-1, 1) translate(-8.999997, -11.999999) "/>
                                        <path d="M10.7071009,15.7071068 C10.3165766,16.0976311 9.68341162,16.0976311 9.29288733,15.7071068 C8.90236304,15.3165825 8.90236304,14.6834175 9.29288733,14.2928932 L15.2928873,8.29289322 C15.6714663,7.91431428 16.2810527,7.90106866 16.6757187,8.26284586 L22.6757187,13.7628459 C23.0828377,14.1360383 23.1103407,14.7686056 22.7371482,15.1757246 C22.3639558,15.5828436 21.7313885,15.6103465 21.3242695,15.2371541 L16.0300699,10.3841378 L10.7071009,15.7071068 Z" fill="#000000" fill-rule="nonzero" opacity="0.3" transform="translate(15.999997, 11.999999) scale(-1, 1) rotate(-270.000000) translate(-15.999997, -11.999999) "/>
                                    </g>
                                </svg>
                            </span>
                        </a>
                        <a class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1"  @click="nextPage">
                            <span class="svg-icon svg-icon-3 cursor-pointer">
                                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                        <polygon points="0 0 24 0 24 24 0 24"/>
                                        <path d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z" fill="#000000" fill-rule="nonzero"/>
                                        <path d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z" fill="#000000" fill-rule="nonzero" opacity="0.3" transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999) "/>
                                    </g>
                                </svg>
                            </span>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div> 
</template>

<script>
/* eslint-disable */
import {defineComponent,toRefs} from "vue";
import { Sketch } from '@ckpack/vue-color';
import vClickOutside from 'click-outside-vue3'
import _ from "lodash";
import { mapActions, mapGetters } from "vuex";
import { useToast } from "vue-toastification";


export default defineComponent({
    directives: {
      clickOutside: vClickOutside.directive
    },
  props:{
    quiz_data:{
      type:Object
    },
    questions_data:{
      type: Array
    },
  },
components: {Sketch},
    setup(props) {
        const {quiz_data: quizData} = toRefs(props);
        const {questions_data: questions} = toRefs(props);
        const toast = useToast();
        return {quizData,questions,toast};
    },
    computed: {
        ...mapGetters({
            getVideosData: "Pexels/getVideosData",
            getCurrentQuestion: "Quiz/getCurrentQuestion",
        }),
    },
    data() {
        return {
            filter: {
                page: 1,
                per_page: 8,
                query: "Ocean",
            },
            library : {}
        }
    },
  
    mounted() {
        this.searchVideos(this.filter);
        this.setLibraryData()
    },
    created() {
    },
    methods: {
        ...mapActions({
        updateQuestionVideoUrl: "Quiz/updateQuestionVideoUrl",
        searchVideos: "Pexels/searchVideos",
        }),
        searchVideoDebounce: _.debounce(function () {
            this.filter.page = 1
            this.searchVideos(this.filter)
        }, 0),
        updateVideoData(video){
            if(this.getCurrentQuestion){
                var videoUrl = video.video_files.find((v) => v.quality='hd' && v.height >= 1000 && v.height <= 1440 && v.width >= 1920 && v.width <= 2560)
                if(videoUrl){
                    this.library.video_id = video.id
                    this.library.video_url = videoUrl.link;
                    this.updateQuestionVideoUrl(this.library).then((response)=>{
                        if(response.data.status==true){
                            var currentIndex = this.questions.findIndex(q => q.id == this.getCurrentQuestion.id);
                            if(currentIndex != -1){
                                if(this.questions[currentIndex] && this.questions[currentIndex].library){
                                    this.questions[currentIndex].library.video_url = null;
                                    setTimeout(() => {
                                        this.questions[currentIndex].library.video_url = videoUrl.link;
                                        this.questions[currentIndex].library.video_id = video.id;
                                    }, 20);
                                }else{
                                    this.questions[currentIndex].library = this.library
                                }
                            }
                            this.toast.success('Video url updated')
                        }
                    })
                }else{
                    this.toast.warning('This video not available in high Resultion,choose diffrent one')
                }
            }
         
        },
        nextPage(){
            this.filter.page += 1
            this.searchVideos(this.filter)
        },
        previousPage(){
            this.filter.page -= 1
            this.searchVideos(this.filter)
        },
        getHqVideo(videoFiles){
            var videoData = videoFiles.find((v) => v.height >= 300 && v.width <= 1000)
            if(videoData)
            return videoData.link
            else
            return null
        },
        playVideo(video){
            var videoId = video.id
            if(this.$refs['video_'+videoId] && this.$refs['video_'+videoId][0]){
                video.playing = true
                this.$refs['video_'+videoId][0].play();
            }
        },
        stopVideo(video){
            var videoId = video.id
            if(this.$refs['video_'+videoId] && this.$refs['video_'+videoId][0]){
                video.playing = false
                this.$refs['video_'+videoId][0].pause();
            }
        },
        setLibraryData(){
            var question = this.questions.find(q => q.id == this.getCurrentQuestion.id);
            if(question && question.library){
                this.library = question.library
            }else{
                this.library = {
                    id : null,
                    question_id: this.getCurrentQuestion.id,
                    quiz_id: this.getCurrentQuestion.quiz_id,
                    image_type : null,
                    image_id : null,
                    image_url : null,
                    upload_url : null,
                    video_id : null,
                    video_url : null,
                }
            }
        }
    },
})
</script>

<style scoped>

.custom-checked{
    width: 40px !important; 
    height: 23px !important;
}
.box {
  background-color: #2D2F3A;
  border-radius: 5px;
  padding: 15px;
}

.custom-editor-tab {
  color: lightgrey;
  padding: 13px;
  display: flex;
  align-items: center;
  gap: 15px;
  justify-content: space-around;
}
.custom-editor-tab-body {
  background-color: #343542;
  height: 93.2%;
  border-radius: 0 0 5px 5px;
  padding: 20px 15px;
  min-height: calc(100vh - 260px);
}
.custom-editor-tab-body h3 {
  color: white;
  font-weight: 400;
}
.custom-tabs {
  color: lightgrey;
  display: flex;
  align-items: center;
  gap: 15px;
}

.custom-tabs .active {
  color: white;
  font-weight: 600;
}

.action-box {
  display: flex;
  align-items: center;
  gap: 40px;
}

.form-check {
  min-height: 0;
}

.vc-input__input{
    color: #202020 !important;
}

.rect{
    width: 23px;
    height: 23px;
}
.input-group-text{
    border: 0;
    background-color: #2D2F3A;
    border-radius: 0 0.475rem 0.475rem 0;
}

.image-block {
  height: 90px;
  border-radius: 6px;
  position: relative;
}

.image-block-edit-icon {
  position: absolute;
  color: #11132d;
  right: 10px;
  background: white;
  font-size: 16px;
  border-radius: 50%;
  height: 25px;
  width: 25px;
  display: flex;
  padding-left: 6px;
  padding-top: 6px;
  top: 7px;
}

.image-radious {
  border-radius: 10px;
}

.bgCover101 {
  background-size: 100% 100% !important;
  -webkit-background-size: 100% 100% !important;
  background-repeat: no-repeat !important;
}

.bgCover {
  background-size: cover !important;
  -webkit-background-size: cover !important;
  background-attachment: fixed !important;
  background-repeat: repeat repeat !important;
  background-position: center center;
}

.bgCover100 {
  background-size: 100% auto !important;
  -webkit-background-size: 100% auto !important;
  background-repeat: no-repeat !important;
}

.bgNoRepeat {
  background-repeat: no-repeat !important;
}

input.vc-input__input{
    color: #202020 !important;
}
.custom-size{
    background-color: #2d2f3a;
    border-radius: 4px;
    padding: 10px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    width: 100%;
}
.form-label{
    color: #e4e6ef;
}
.suffle-button{
    background-color: #3D3F4C;
    color: #e4e6ef;
}
.form-check.form-check-solid .form-check-input {
    border: 0;
    background-color: #3d3f4c;
}
.form-check.form-check-solid .form-check-input:checked {
    background-color: #e49c35;
}
.form-control.form-control-solid, .form-select-solid {
    background-color: #3d3f4c;
    border-color: #3d3f4c;
    color: #c4c7cc;
    transition: color .2s ease,background-color .2s ease;
}
.dropdown.show > .form-select.form-select-solid, .form-select.form-select-solid:active, .form-select.form-select-solid.active, .form-select.form-select-solid:focus, .form-select.form-select-solid.focus {
    background-color: #3d3f4c;
    border-color: #3d3f4c;
    color: #c4c7cc;
    transition: color 0.2s ease, background-color 0.2s ease;
}
.btn-custom{
    background-color: #2D2F3A !important;
    color: #c4c7cc;
    width: 60px !important;
}
.active{
    border: 1px solid #e49c35;
}
</style>