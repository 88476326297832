<template>
    <div class="row g-xxl-9">
        <div class="col-md-6 col-lg-6" id="redirect_left_div">
            <div class="bg-white p-6 rounded-xl">
                <div class="d-flex align-items-center justify-content-between">
                    <h2 class="mb-1">Build Questions using AI</h2>
                    <div class="hidden-switch d-flex ml-4 ">
                        <div class="form-check form-check-solid form-switch fv-row">
                            <label class="form-check-label text-black" for="add-scoring">Add Scoring</label>
                            <input class="custom-checked form-check-input w-45px h-30px" type="checkbox"
                                id="add-scoring" v-model="score_enable" style="width: 40px !important; height: 23px !important; ">
                        </div>
                    </div>
                </div>

                <form @submit.prevent="submit">
                    <div class="mt-5 mb-10">
                        <label class="form-label fs-6 fw-bolder text-dark">AI Prompt:</label>
                        <input type="text" class="form-control" v-model="ai.ai_prompt"
                            placeholder="Please enter you prompt">

                        <div class="input-errors" v-for="(error, index) of v$.ai.ai_prompt.$errors" :key="index">
                            <div class="error-msg">
                                this filed is required
                            </div>
                        </div>
                    </div>
                    <div class="mt-5 mb-10">
                        <label class="form-label fs-6 fw-bolder text-dark">Number of questions:</label>
                        <input type="number" v-model="ai.question" class="form-control">

                        <div class="input-errors" v-for="(error, index) of v$.ai.question.$errors" :key="index">
                            <div class="error-msg" v-if="error.$validator == 'numeric'">
                                Please enter Number
                            </div>
                            <div class="error-msg" v-if="error.$validator == 'maxLength'">
                                Please enter maximum 20 number
                            </div>
                            <div class="error-msg" v-else>
                                Email address is required
                            </div>
                        </div>
                    </div>
                    <div v-if="score_enable">

                        <div class="mt-5 mb-10">
                            <label class="form-label fs-6 fw-bolder text-dark">Most effective score:</label>
                            <input type="number" v-model="ai.correct" class="form-control">

                            <div class="input-errors" v-for="(error, index) of v$.ai.correct.$errors" :key="index">
                                <div class="error-msg" v-if="error.$validator == 'numeric'">
                                    Please enter Number
                                </div>
                                <div class="error-msg" v-else>
                                    Score is required
                                </div>
                            </div>
                        </div>
                        <div class="mt-5 mb-10">
                            <label class="form-label fs-6 fw-bolder text-dark">Less effective score:</label>
                            <input type="number" v-model="ai.wrong" class="form-control">

                            <div class="input-errors" v-for="(error, index) of v$.ai.wrong.$errors" :key="index">
                                <div class="error-msg" v-if="error.$validator == 'numeric'">
                                    Please enter Number
                                </div>
                                <div class="error-msg" v-else>
                                    Score is required
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="mt-5 mb-10">
                        <label class="form-label fs-6 fw-bolder text-dark"> Difficulty level:</label>
                        <select class="form-select" v-model="ai.type">
                            <option value="easy">Easy to understand </option>
                            <option value="difficult">College level</option>
                        </select>
                    </div>
                    <div class="mt-5 mb-10 d-flex justify-content-end">
                        <button class="btn btn-primary" :disabled="loader">
                            <!-- <div class="spinner-border spinner-border-sm mr-2" v-if="loader" role="status">
                                <span class="visually-hidden">Loading...</span>
                            </div> -->
                            Generate
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>
    <div class="loader" v-if="loader">
        <div class="d-flex flex-column align-items-center justify-content-center">
            <div class="spinner-border text-primary mr-2" style="width: 5rem; height: 5rem; border-width: .4rem;"
                role="status">
                <span class="visually-hidden">Loading...</span>
            </div>
            <p class="fs-2 mt-4">Building out your Questionaire</p>
        </div>
    </div>
</template>

<script>
import useVuelidate from "@vuelidate/core";
import { required, numeric, maxValue } from "@vuelidate/validators";
import { mapActions } from 'vuex'
// import _ from "lodash";
import { useToast } from "vue-toastification";
import { defineComponent, toRefs } from "vue";
export default defineComponent({
    name: "Score-box",
    props: {
        quizData: {
            type: Object,
        },
        quizId: Number,
    },
    setup(props) {
        const { quizData: quiz } = toRefs(props);
        const toasts = useToast();
        return {
            quiz,
            toasts,
            v$: useVuelidate()
        };
    },
    data() {
        return {
            ai: {
                ai_prompt: null,
                question: 5,
                type: 'easy',
                correct: 1,
                wrong: 0,
            },
            loader: false,
            score_enable : false,
        }
    },
    validations() {
        return {
            ai: {
                ai_prompt: { required },
                question: { required, numeric, maxLength: maxValue(20) },
                correct: { required, numeric },
                wrong: { required, numeric },
            },
        };
    },
    methods: {
        ...mapActions({
            generateAiQuestion: 'Quiz/generateAiQuestion'
        }),

        submit() {
            this.v$.ai.$touch();
            if (this.v$.ai.$invalid) {
                return;
            }
            this.loader = true

            let payload = {
                quiz_id: this.quiz.id,
                ai_prompt: this.ai.ai_prompt,
                question: this.ai.question,
                type: this.ai.type,
                correct_answer: this.ai.correct,
                wrong_answer: this.ai.wrong,
                score_enable : this.score_enable
            }
            this.generateAiQuestion(payload)
                .then((response) => {
                    console.log(response)
                    this.loader = false;
                    if (response.data.status == true) {
                        this.$emit('changeTab', 1)
                    }else {
                        this.toasts.error("Please enter valid prompt");
                    }
                    // window.location.reload()
                })
                .catch((error) => {
                    this.loader = false
                    this.toast.error(error.response.data.message);
                });
        },
    },
});
</script>

<style scoped>
.is-invalid {
    border-color: #f1416c !important;
}

.error-msg {
    font-size: 12px;
    color: #f1416c;
    margin-top: 2px;
}

.score-name {
    border: none;
    border-bottom: 3px dotted #bbb;
    max-width: 100px;
}

.form-check-input {
    border: 0;
    background-color: #3d3f4c;
}

.form-check-input:checked {
    background-color: #e49c35;
}

.custom-checked {
    width: 40px !important;
    height: 23px !important;
}

.title-group:hover .pen {
    visibility: visible;
}

.pen {
    visibility: hidden;
}

.loader {
    height: 100vh;
    width: 100vw;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 50;
    background-color: #00000080;
    display: grid;
    place-items: center;
}
</style>
