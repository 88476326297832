<template>
    <div class="bg-white text-black px-5 pt-8 pb-5 max-480">
        <div>
            <div class="border px-4 py-3 rounded d-flex justify-content-between align-items-center mb-2"
                v-for="(category, index) in allCategory" :key="index">
                <p class="m-0 fw-bold text-lg">{{ category.name }}</p>
                <div class="d-flex justify-content-between align-items-center">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="w-5 text-success pointer"
                        fill="currentColor" @click="editCategory(category)">
                        <path
                            d="M441 58.9L453.1 71c9.4 9.4 9.4 24.6 0 33.9L424 134.1 377.9 88 407 58.9c9.4-9.4 24.6-9.4 33.9 0zM209.8 256.2L344 121.9 390.1 168 255.8 302.2c-2.9 2.9-6.5 5-10.4 6.1l-58.5 16.7 16.7-58.5c1.1-3.9 3.2-7.5 6.1-10.4zM373.1 25L175.8 222.2c-8.7 8.7-15 19.4-18.3 31.1l-28.6 100c-2.4 8.4-.1 17.4 6.1 23.6s15.2 8.5 23.6 6.1l100-28.6c11.8-3.4 22.5-9.7 31.1-18.3L487 138.9c28.1-28.1 28.1-73.7 0-101.8L474.9 25C446.8-3.1 401.2-3.1 373.1 25zM88 64C39.4 64 0 103.4 0 152V424c0 48.6 39.4 88 88 88H360c48.6 0 88-39.4 88-88V312c0-13.3-10.7-24-24-24s-24 10.7-24 24V424c0 22.1-17.9 40-40 40H88c-22.1 0-40-17.9-40-40V152c0-22.1 17.9-40 40-40H200c13.3 0 24-10.7 24-24s-10.7-24-24-24H88z" />
                    </svg>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" class="w-4 ml-4 text-danger pointer"
                        fill="currentColor" @click="removeCategory(category.id)">
                        <path
                            d="M135.2 17.7L128 32H32C14.3 32 0 46.3 0 64S14.3 96 32 96H416c17.7 0 32-14.3 32-32s-14.3-32-32-32H320l-7.2-14.3C307.4 6.8 296.3 0 284.2 0H163.8c-12.1 0-23.2 6.8-28.6 17.7zM416 128H32L53.2 467c1.6 25.3 22.6 45 47.9 45H346.9c25.3 0 46.3-19.7 47.9-45L416 128z" />
                    </svg>
                </div>
            </div>
        </div>

        <div class="d-flex justify-content-end mt-5">
            <button class="ml-8px text-primary text-decoration-underline" @click="isShowModel = true">+ Add category</button>
        </div>
    </div>

    <Model modaltitle="Add Category" v-if="isShowModel" @close="modelClose">
        <template #body>
            <form @submit.prevent="addCategory">
                <div class="modal-body">
                    <div>
                        <p class="fw-bold text-black">Name</p>
                        <input type="text" class="form-control" placeholder="Enter category name" v-model="category.name"
                            :class="{
                                'is-invalid custom-border': v$.category.$errors.length,
                            }">
                        <p class="text-sm mt-1 text-danger" v-if="v$.category.$errors.length">Name is required</p>
                    </div>
                    <div class="py-5">
                        <p class="fw-bold text-black">Description</p>
                        <textarea type="text" class="form-control" v-model="category.description"
                            placeholder="Enter Description"></textarea>
                    </div>
                    <div>
                        <p class="fw-bold text-black">Icon</p>
                        <input type="file" class="form-control">
                    </div>
                    <div class="py-5">
                        <p class="fw-bold text-black">Scoring Logic</p>
                        <select class="form-select" v-model="category.logic">
                            <option :value="1">Add category score to total score</option>
                            <option :value="2">Do not effect total score</option>
                        </select>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-light" data-bs-dismiss="modal" @click="modelClose">
                        Close
                    </button>
                    <button type="submit" class="btn btn-primary">Submit</button>
                </div>
            </form>
        </template>
    </Model>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { defineComponent } from "vue";
import Model from "@/components/custom-components/model.vue";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import { useToast } from "vue-toastification";
export default defineComponent({
    components: {
        Model
    },
    props: {
        quizData: {
            type: Object
        }
    },
    setup() {
        const toast = useToast();
        return { toast, v$: useVuelidate() };
    },
    data() {
        return {
            isShowModel: false,
            allCategory: [],
            category: {
                id : null,
                name: null,
                description: null,
                icon: null,
                logic: 1
            }
        };
    },
    validations() {
        return {
            category: {
                name: { required }
            }
        };
    },
    computed: {
        ...mapGetters({
        }),
    },
    watch: {
    },
    mounted() {
        this.getCategory()
    },
    methods: {
        ...mapActions({
            addQuizCategory: 'Quiz/addQuizCategory',
            getQuizCategory: 'Quiz/getQuizCategory',
            removeQuizCategory: 'Quiz/removeQuizCategory',
        }),

        addCategory() {
            this.v$.category.$touch();
            if (this.v$.category.$invalid) {
                return
            }
            let payload = {
                id : this.category.id,
                quiz_id: this.quizData.id,
                name: this.category.name,
                description: this.category.description,
                scoring_logic: this.category.logic
            }

            this.addQuizCategory(payload)
                .then(
                    (res) => {
                        if (res.data.status) {
                            this.toast.success('Quiz Category Added Successfully');
                            this.getCategory();
                            this.modelClose();
                        } else {
                            if (!res.data.status) {
                                this.toast.error(res.data.message);
                            }
                        }
                    }
                ).catch((error) => {
                    this.toast.error(error.message);
                });
        },
        getCategory() {
            this.getQuizCategory({ quiz_id: this.quizData.id })
                .then(
                    (res) => {
                        if (res.data.status) {
                            this.allCategory = res.data.data
                        }
                    }
                ).catch((error) => {
                    this.toast.error(error.message);
                });
        },
        removeCategory(id) {
            this.removeQuizCategory(id)
                .then(
                    (res) => {
                        if (res.data.status) {
                            this.toast.success('Quiz category remove Successfully');
                            this.getCategory();
                        }
                    }
                ).catch((error) => {
                    this.toast.error(error.message);
                });
        },
        modelClose() {
            this.isShowModel = false;
            this.category = {
                id : null,
                name: null,
                description: null,
                icon: null,
                logic: 1
            }
        },
        editCategory(category) {

            this.isShowModel = true;

            this.category = {
                id : category.id,
                name : category.name,
                description : category.description,
                icon : category.icon,
                logic : category.scoring_logic
            }
        }
    },
});
</script>

<style scoped >
.max-480 {
    max-width: 480px;
}

.ml-8px {
    margin-left: 8px;
}

.pointer {
    cursor: pointer;
}
</style>