<template>
  <div class="row editor-container">
    <div class="col-xl-12">
      <div class="editor-area">
        <div class="row">
          <div class="col-md-9 col-sm-12">
            <div class="d-flex align-items-center justify-content-between mb-9 box-navbar">
              <div class="box custom-tabs">
                <div v-if="activeTab == 9 && viewType == 'editor'" @click="changeTab(9); updateView('editor')"
                  class="cursor-pointer" :class="activeTab == 9 && viewType == 'editor' ? 'active' : ''">
                  Designer
                </div>
                <div v-else @click="changeTab(1)" class="cursor-pointer" :class="activeTab == 1 ? 'active' : ''">
                  Questionaire
                </div>
                <!-- <div @click="changeTab(9);updateView('editor')" class="cursor-pointer" :class="activeTab==9 && viewType == 'editor'? 'active' : ''">
                  Design
                </div> -->
                <span style="font-size: 15px; color: gray;" v-if="getquizSingleData.score_redirect_status">|</span>
                <div class="cursor-pointer" @click="changeTab(12)" v-if="getquizSingleData.score_redirect_status" :class="activeTab == 12 ? 'active' : ''">
                  Category
                </div>
                <span style="font-size: 15px; color: gray;">|</span>
                <div class="cursor-pointer" @click="changeTab(10)" :class="activeTab == 10 ? 'active' : ''">
                  Intro
                </div>
                <span style="font-size: 15px; color: gray;">|</span>
                <div class="cursor-pointer" @click="changeTab(11)" :class="activeTab == 11 ? 'active' : ''">
                  Exit
                </div>
                <!-- <div @click="changeTab(3)" class="cursor-pointer" :class="activeTab==3 ? 'active' : ''">
                  Redirects
                </div> -->
                 <!-- <span style="font-size: 15px; color: gray;">|</span>
                <div @click="changeTab(4)" class="cursor-pointer" :class="activeTab==4 ? 'active' : ''">
                  Automation
                </div>  -->
                <!--<span style="font-size: 15px; color: gray;">|</span>
                <div @click="changeTab(5)" class="cursor-pointer" :class="activeTab==5 ? 'active' : ''">
                  Jump Logic
                </div>
                <span style="font-size: 15px; color: gray;">|</span>
                <div @click="changeTab(6)" class="cursor-pointer" :class="activeTab==6 ? 'active' : ''">
                  Publish
                </div>
                <span style="font-size: 15px; color: gray;">|</span>
                <div @click="changeTab(7)" class="cursor-pointer" :class="activeTab==7 ? 'active' : ''">
                  Entries
                </div>
                <span style="font-size: 15px; color: gray;">|</span>
                <div @click="changeTab(8)" class="cursor-pointer" :class="activeTab==8 ? 'active' : ''">
                  Statistics
                </div> -->
              </div>
              <div class="box d-flex">
                <!-- <div class="d-flex justify-content-between align-items-center form-check form-check-solid form-switch fv-row ps-0 w-150px pe-5">
                    <label class="form-check-label" for="add-jump-logic">Jump Logic</label>
                    <input class="custom-checked form-check-input w-35px h-20px" type="checkbox" id="add-jump-logic">
                </div> -->
                <!-- <div class="pe-4 d-flex align-items-center" @click="changeTab(4)" data-bs-toggle="tooltip"
                  title="Automation">
                  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                    :fill="activeTab == 4 ? '#e49c35' : '#A7A8AB'" width="24" height="24" version="1.1" id="Capa_1"
                    viewBox="0 0 490 490" xml:space="preserve">
                    <g>
                      <g>
                        <g>
                          <path
                            d="M176.7,165L95.2,268c-8.4-3.7-17.6-5.8-27.3-5.8C30.5,262.2,0,292.7,0,330.1S30.5,398,67.9,398s67.9-30.5,67.9-67.9     c0-15.3-5.1-29.5-13.7-40.9l81.5-103c7.5,3.9,15.7,6.8,24.2,8.4v145.5c-29.2,7.6-50.8,34.2-50.8,65.7c0,37.5,30.5,67.9,67.9,67.9     s67.9-30.5,67.9-67.9c0-31.5-21.6-58.1-50.8-65.7V194.7c8.3-1.6,16.1-4.3,23.4-8L367,290.2c-8.2,11.2-13.1,25-13.1,40     c0,37.4,30.5,67.9,67.9,67.9s68.2-30.5,68.2-68c0-37.4-30.5-67.9-67.9-67.9c-10.1,0-19.7,2.2-28.3,6.2l-81.1-102.8     c13.9-15.9,22.4-36.6,22.4-59.3c0-49.6-40.4-90-90-90s-90,40.4-90,90C155,128.7,163.2,149.2,176.7,165z M67.9,363.8     c-18.5,0-33.6-15.1-33.6-33.6s15.1-33.6,33.6-33.6s33.6,15.1,33.6,33.6S86.5,363.8,67.9,363.8z M278.6,406     c0,18.5-15.1,33.6-33.6,33.6s-33.6-15.1-33.6-33.6c0-18.3,14.7-33.2,33-33.6c0.2,0,0.4,0,0.7,0c0.2,0,0.4,0,0.7,0     C263.9,372.7,278.6,387.7,278.6,406z M455.7,330.1c0,18.5-15.1,33.6-33.6,33.6s-33.6-15.1-33.6-33.6s15.1-33.6,33.6-33.6     S455.7,311.6,455.7,330.1z M245,50.6c30.7,0,55.7,25,55.7,55.7S275.7,162,245,162s-55.7-25-55.7-55.7S214.3,50.6,245,50.6z" />
                        </g>
                      </g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                    </g>
                  </svg>
                </div> -->
                <div @click="changeTab(14)" class="pe-4 d-flex align-items-center"
                  data-bs-toggle="tooltip" title="Template Gallery">
                      <svg xmlns="http://www.w3.org/2000/svg" class="cursor-pointer fill-current w-6 fs-2"
                    :class="activeTab == 14 ? 'active-text' : 'text-[#a1a5b7]'" viewBox="0 0 512 512">
                        <path d="M176 24c0-13.3-10.7-24-24-24s-24 10.7-24 24V64c-35.3 0-64 28.7-64 64H24c-13.3 0-24 10.7-24 24s10.7 24 24 24H64v56H24c-13.3 0-24 10.7-24 24s10.7 24 24 24H64v56H24c-13.3 0-24 10.7-24 24s10.7 24 24 24H64c0 35.3 28.7 64 64 64v40c0 13.3 10.7 24 24 24s24-10.7 24-24V448h56v40c0 13.3 10.7 24 24 24s24-10.7 24-24V448h56v40c0 13.3 10.7 24 24 24s24-10.7 24-24V448c35.3 0 64-28.7 64-64h40c13.3 0 24-10.7 24-24s-10.7-24-24-24H448V280h40c13.3 0 24-10.7 24-24s-10.7-24-24-24H448V176h40c13.3 0 24-10.7 24-24s-10.7-24-24-24H448c0-35.3-28.7-64-64-64V24c0-13.3-10.7-24-24-24s-24 10.7-24 24V64H280V24c0-13.3-10.7-24-24-24s-24 10.7-24 24V64H176V24zM160 128H352c17.7 0 32 14.3 32 32V352c0 17.7-14.3 32-32 32H160c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32zm192 32H160V352H352V160z"/>
                      </svg>
                </div>
                <div @click="changeTab(4)" class="pe-4 d-flex align-items-center"
                  data-bs-toggle="tooltip" title="Template Gallery">
                      <svg xmlns="http://www.w3.org/2000/svg" class="cursor-pointer fill-current w-6 fs-2"
                    :class="activeTab == 4 ? 'active-text' : 'text-[#a1a5b7]'" viewBox="0 0 448 512">
                        <path d="M0 80V229.5c0 17 6.7 33.3 18.7 45.3l176 176c25 25 65.5 25 90.5 0L418.7 317.3c25-25 25-65.5 0-90.5l-176-176c-12-12-28.3-18.7-45.3-18.7H48C21.5 32 0 53.5 0 80zm112 32a32 32 0 1 1 0 64 32 32 0 1 1 0-64z"/>
                      </svg>
                </div>
                <div @click="changeTab(13)" class="pe-4 d-flex align-items-center"
                  data-bs-toggle="tooltip" title="Template Gallery">
                    <svg xmlns="http://www.w3.org/2000/svg" class="cursor-pointer fill-current w-6 fs-2"
                    :class="activeTab == 13 ? 'active-text' : 'text-[#a1a5b7]'" viewBox="0 0 512 512">
                      <path d="M0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256zm320 96c0-26.9-16.5-49.9-40-59.3V88c0-13.3-10.7-24-24-24s-24 10.7-24 24V292.7c-23.5 9.5-40 32.5-40 59.3c0 35.3 28.7 64 64 64s64-28.7 64-64zM144 176a32 32 0 1 0 0-64 32 32 0 1 0 0 64zm-16 80a32 32 0 1 0 -64 0 32 32 0 1 0 64 0zm288 32a32 32 0 1 0 0-64 32 32 0 1 0 0 64zM400 144a32 32 0 1 0 -64 0 32 32 0 1 0 64 0z"/></svg>
                </div>
                <div @click="changeTab(9); updateView('gallery')" class="pe-4 d-flex align-items-center"
                  data-bs-toggle="tooltip" title="Template Gallery">
                  <i class="fa fa-palette fa-lg cursor-pointer fs-2"
                    :class="activeTab == 9 && viewType == 'gallery' ? 'active-text' : ''"></i>
                </div>
                <div @click="viewInBrowser" class=" d-flex align-items-center" data-bs-toggle="tooltip"
                  title="View In Browser">
                  <i class="fa fa-eye fa-lg cursor-pointer fs-2"></i>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-3 col-sm-12">
            <div class="d-flex align-items-center  mb-9">
              <div class="box custom-tabs justify-content-evenly w-100">
                <div v-if="activeTab == 9 && viewType == 'editor'" @click="changeTab(1)" data-bs-toggle="tooltip"
                  title="Questionaire">
                  <svg :fill="activeTab == 1 ? '#e49c35' : '#a2a4b7'" xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="20px" height="20px"
                    viewBox="0 0 256 256" xml:space="preserve">
                    <defs>
                    </defs>
                    <g style="stroke: none; stroke-width: 0; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: none; fill-rule: nonzero; opacity: 1;"
                      transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)">
                      <path :fill="activeTab == 1 ? '#e49c35' : '#a2a4b7'"
                        d="M 27.429 72.429 c -2.209 0 -4 -1.791 -4 -4 v -11.8 c 0 -2.209 1.791 -4 4 -4 s 4 1.791 4 4 v 11.8 C 31.429 70.638 29.638 72.429 27.429 72.429 z"
                        style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill-rule: nonzero; opacity: 1;"
                        transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round" />
                      <path :fill="activeTab == 1 ? '#e49c35' : '#a2a4b7'"
                        d="M 45 72.429 c -2.209 0 -4 -1.791 -4 -4 V 45 c 0 -2.209 1.791 -4 4 -4 s 4 1.791 4 4 v 23.429 C 49 70.638 47.209 72.429 45 72.429 z"
                        style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill-rule: nonzero; opacity: 1;"
                        transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round" />
                      <path :fill="activeTab == 1 ? '#e49c35' : '#a2a4b7'"
                        d="M 62.571 72.429 c -2.209 0 -4 -1.791 -4 -4 V 21.571 c 0 -2.209 1.791 -4 4 -4 s 4 1.791 4 4 v 46.857 C 66.571 70.638 64.78 72.429 62.571 72.429 z"
                        style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill-rule: nonzero; opacity: 1;"
                        transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round" />
                      <path :fill="activeTab == 1 ? '#e49c35' : '#a2a4b7'"
                        d="M 77.403 90 H 12.597 C 5.651 90 0 84.35 0 77.403 V 12.597 C 0 5.651 5.651 0 12.597 0 h 64.807 C 84.35 0 90 5.651 90 12.597 v 64.807 C 90 84.35 84.35 90 77.403 90 z M 12.597 8 C 10.062 8 8 10.062 8 12.597 v 64.807 C 8 79.938 10.062 82 12.597 82 h 64.807 C 79.938 82 82 79.938 82 77.403 V 12.597 C 82 10.062 79.938 8 77.403 8 H 12.597 z"
                        style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill-rule: nonzero; opacity: 1;"
                        transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round" />
                    </g>
                  </svg>
                </div>
                <div v-else @click="changeTab(9); updateView('editor')" data-bs-toggle="tooltip" title="Template Editor">
                  <svg :fill="activeTab == 9 && viewType == 'editor' ? '#e49c35' : '#a2a4b7'" width="20px" height="20px"
                    viewBox="0 0 256 256" id="Flat" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M224.9707,19.0293c-22.31836,0-47.14355,12.08593-73.78613,35.9209-17.36133,15.53125-32.36816,33.18457-43.12891,47.09082A64.01933,64.01933,0,0,0,28,164c0,28.56445-14.31641,41.60156-15.5498,42.6709a11.80639,11.80639,0,0,0-3.94532,13.25A12.07044,12.07044,0,0,0,20,228H92a64.01976,64.01976,0,0,0,61.959-80.05615c13.90577-10.76026,31.55909-25.76807,47.09082-43.1294,23.83594-26.6416,35.9209-51.46679,35.9209-73.78515A12.00059,12.00059,0,0,0,224.9707,19.0293ZM92,204H43.28516C48.03027,194.2207,52,180.96875,52,164a40,40,0,1,1,40,40Zm38.21191-91.30908c3.01807-3.79248,6.45508-7.97315,10.23633-12.34863a80.65046,80.65046,0,0,1,15.18848,15.18115c-4.42236,3.83056-8.60156,7.273-12.33594,10.25293A64.54822,64.54822,0,0,0,130.21191,112.69092ZM173.291,99.15234a104.74491,104.74491,0,0,0-16.48047-16.47265c3.34228-3.34473,6.81006-6.65235,10.376-9.84278,15.68262-14.03027,30.42676-23.30175,43.42481-27.39941C204.85352,63.93066,189.37158,83.09717,173.291,99.15234Z" />
                  </svg>
                </div>
                <!-- <div @click="changeTab(1)" data-bs-toggle="tooltip" title="Questionaire">
                  <svg :fill="activeTab==1  ? '#e49c35' : '#a2a4b7'" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="20px" height="20px" viewBox="0 0 256 256" xml:space="preserve">
                  <defs>
                  </defs>
                  <g style="stroke: none; stroke-width: 0; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: none; fill-rule: nonzero; opacity: 1;" transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)">
                    <path :fill="activeTab==1 ? '#e49c35' : '#a2a4b7'" d="M 27.429 72.429 c -2.209 0 -4 -1.791 -4 -4 v -11.8 c 0 -2.209 1.791 -4 4 -4 s 4 1.791 4 4 v 11.8 C 31.429 70.638 29.638 72.429 27.429 72.429 z" style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill-rule: nonzero; opacity: 1;" transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round"/>
                    <path :fill="activeTab==1 ? '#e49c35' : '#a2a4b7'" d="M 45 72.429 c -2.209 0 -4 -1.791 -4 -4 V 45 c 0 -2.209 1.791 -4 4 -4 s 4 1.791 4 4 v 23.429 C 49 70.638 47.209 72.429 45 72.429 z" style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill-rule: nonzero; opacity: 1;" transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round"/>
                    <path :fill="activeTab==1 ? '#e49c35' : '#a2a4b7'" d="M 62.571 72.429 c -2.209 0 -4 -1.791 -4 -4 V 21.571 c 0 -2.209 1.791 -4 4 -4 s 4 1.791 4 4 v 46.857 C 66.571 70.638 64.78 72.429 62.571 72.429 z" style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill-rule: nonzero; opacity: 1;" transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round"/>
                    <path :fill="activeTab==1 ? '#e49c35' : '#a2a4b7'" d="M 77.403 90 H 12.597 C 5.651 90 0 84.35 0 77.403 V 12.597 C 0 5.651 5.651 0 12.597 0 h 64.807 C 84.35 0 90 5.651 90 12.597 v 64.807 C 90 84.35 84.35 90 77.403 90 z M 12.597 8 C 10.062 8 8 10.062 8 12.597 v 64.807 C 8 79.938 10.062 82 12.597 82 h 64.807 C 79.938 82 82 79.938 82 77.403 V 12.597 C 82 10.062 79.938 8 77.403 8 H 12.597 z" style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill-rule: nonzero; opacity: 1;" transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round"/>
                  </g>
                  </svg>
                </div> -->
                <!-- <span style="font-size: 15px; color: gray;">|</span>
                <div @click="changeTab(4)" class="cursor-pointer" :class="activeTab==4 ? 'active' : ''">
                  Automation
                </div> -->
                <!-- <span style="font-size: 15px; color: gray;">|</span>
                <div @click="changeTab(5)" class="cursor-pointer" :class="activeTab==5 ? 'active' : ''">
                  Jump
                </div> -->
                <span style="font-size: 15px; color: gray;">|</span>
                <div @click="changeTab(7)" class="cursor-pointer" :class="activeTab == 7 ? 'active' : ''">
                  Entries
                </div>
                <span style="font-size: 15px; color: gray;">|</span>
                <div @click="changeTab(8)" class="cursor-pointer" :class="activeTab == 8 ? 'active' : ''">
                  Stats
                </div>
                <!-- <span style="font-size: 15px; color: gray;">|</span> -->
                <!-- <div @click="changeTab(6)" class="cursor-pointer" :class="activeTab == 6 ? 'active' : ''">
                  Publish
                </div> -->
              </div>
            </div>
          </div>
        </div>
        <component @changeTab="(val) => changeTab(val)" :is="current_component" :quizData="getquizSingleData" :ref="current_component + 'QuizDetail'" />
      </div>
    </div>
  </div>
</template>

<script>
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import TabTopBar from './components/TabBar.vue'
import Questionaire from '@/components/custom-components/quiz/Questionaire.vue'
import Automation from '@/components/custom-components/quiz/Automation.vue'
import Theme from '@/components/custom-components/quiz/Theme.vue'
import Publish from '@/components/custom-components/quiz/Publish.vue'
import Score from '@/components/custom-components/quiz/Scoring.vue'
import Ai from '@/components/custom-components/quiz/Ai.vue'
import Entries from '@/components/custom-components/quiz/Entries.vue'
import Statistics from '@/components/custom-components/quiz/StatisticsScreen.vue'
import { default as Redirect } from '@/components/custom-components/quiz/Redirect.vue'
import JumpLogic from '@/components/custom-components/quiz/JumpLogic.vue'
import Template from '@/components/custom-components/quiz/Template.vue'
import Intro from '@/components/custom-components/quiz/redirect/WelcomeScreen.vue'
import Category from '@/components/custom-components/quiz/Category.vue'
// import Exit from '@/components/custom-components/quiz/redirect/RedirectScreenOld.vue'
import Exit from '@/components/custom-components/quiz/redirect/RedirectScreen.vue'
import { defineComponent } from 'vue'
import { mapActions, mapGetters } from "vuex";
import { useToast } from "vue-toastification";

export default defineComponent({
  components: {
    TabTopBar,
    Questionaire,
    Theme,
    Redirect,
    Publish,
    Entries,
    Automation,
    Statistics,
    Score,
    JumpLogic,
    Template,
    Intro,
    Exit,
    Category,
    Ai
  },
  // setup() {
  //     onMounted(() => {
  //         setCurrentPageTitle('aaa');
  //     });
  // },
  setup() {
    const toast = useToast();
    return { toast };
  },
  watch: {
    getquizSingleData() {
      setCurrentPageTitle(this.getquizSingleData.name);
    },
    current_component() {
      this.setBgColor()
    },
    isGCUpdate(newVal , oldVal) {
      this.getTagList();
    },
    questions(newVal) {
      if (newVal.length) {
        this.setCurrentQuestionQuiz(newVal[0])
      }
    }
  },
  computed: {
    ...mapGetters({
      getquizSingleData: 'Quiz/getquizSingleData',
      QuestiosData: 'Quiz/getQuestions',
      isGCUpdate: 'GC/isGCUpdate',
    }),
    questions() {
      return this.QuestiosData && this.QuestiosData.result ? this.QuestiosData.result : []
    },
    dynamicProps() {
      if (this.current_component == 'Template') {
        return { current_tab: 'gallery' }
      }
      return null
    },
    quizMenus() {
      return this.current_component == 'Template' || this.current_component == 'Intro' || this.current_component == 'Exit' ? true : false
    }
  },
  data() {
    return {
      activeTab: 1,
      current_component: "Questionaire",
      loader: false,
      tabIndex: 0,
      viewType: '',
      assetUrl: process.env.VUE_APP_ENVIROMENT != 'local' ? process.env.VUE_APP_API_URL : "http://127.0.0.1:8000",
      // getquizSingleData:{}
    }
  },

  methods: {
    ...mapActions({
      getSingleQuiz: "Quiz/getSingleQuiz",
      setQuestion: "Quiz/setQuestions",
      getallTemplate: "Quiz/getallTemplate",
      getTagList: "GC/getTagList",
      setCurrentQuestionQuiz:'Quiz/setCurrentQuestion'
    }),
    getQuizData(id) {
      this.loader = true
      this.getSingleQuiz(id).then((res) => {
        this.loader = false
        if (res.data.status == true) {
          // this.getquizSingleData=res.data.data
        } else {
          this.$router.push({ name: 'QuizIndex' })
        }
      }).catch(() => {
        this.loader = false
        this.$router.push({ name: 'QuizIndex' })
      })
    },
    changeTab(tab) {
      if (tab == 1) this.current_component = 'Questionaire'
      if (tab == 2) this.current_component = 'Theme'
      if (tab == 4) this.current_component = 'Automation'
      if (tab == 5) this.current_component = 'JumpLogic'
      if (tab == 6) this.current_component = 'Publish'
      if (tab == 7) this.current_component = 'Entries'
      if (tab == 8) this.current_component = 'Statistics'
      if (tab == 9) this.current_component = 'Template'
      if (tab == 10) this.current_component = 'Intro'
      if (tab == 11) this.current_component = 'Exit'
      if (tab == 12) this.current_component = 'Category'
      if (tab == 13) this.current_component = 'Score'
      if (tab == 14) this.current_component = 'Ai'
      this.activeTab = tab
      if (this.$route.query.tab && this.$route.query.tab == 'entries' || this.$route.query.tab == 'statistics' || this.$route.query.tab == 'template') {
        window.history.pushState({}, document.title, window.location.pathname);
      }
    },
    viewInBrowser() {
      let copyUrl = null
      if (this.getquizSingleData.links && this.getquizSingleData.links.keyword) {
        copyUrl = this.assetUrl + "/" + this.getquizSingleData.links.keyword
      } else {
        copyUrl = this.assetUrl + "/page/quiz/" + this.getquizSingleData.unique_code
      }
      // let copyUrl = this.assetUrl + "/page/quiz/" + this.getquizSingleData.unique_code
      window.open(copyUrl, '_blank');
    },
    updateView(type) {
      setTimeout(() => {
        if (this.$refs.TemplateQuizDetail) {
          this.viewType = type
          this.$refs.TemplateQuizDetail.updateView(type)
        }
      }, 10)
    },
    getallQuestion(type = 'all') {
      this.showloader = true
      let data = {
        type: type,
        quiz_id: this.$route.params.id
      }
      this.setQuestion(data)
    },
    setBgColor() {
      this.current_component == 'Template' || this.current_component == 'Intro' || this.current_component == 'Exit' ? document.body.style.background = '#202020' : document.body.style.background = '#F2F5F8';
    }
  },
  created() {
    if (this.$route.params.id) {
      this.getQuizData(this.$route.params.id)
    }
    if (this.$route.query.tab) {
      if (this.$route.query.tab == 'questionaire') {
        this.current_component = 'Questionaire'
        this.activeTab = 1
      } else if (this.$route.query.tab == 'entries') {
        this.current_component = 'Entries'
        this.activeTab = 7
      } else if (this.$route.query.tab == 'statistics') {
        this.current_component = 'Statistics'
        this.activeTab = 8
      } else if (this.$route.query.tab == 'template') {
        this.current_component = 'Template'
        this.activeTab = 9
      }
    }
    this.setBgColor()
  },
  mounted() {
    // this.getallTemplate();
    if (this.$route.query && this.$route.query.mode == "gallery" && this.$route.query.tab == "template") {
      this.viewType = 'gallery'
      this.activeTab = 9
    } else if (this.$route.query && this.$route.query.mode == "editor" && this.$route.query.tab == "template") {
      this.viewType = 'editor'
      this.activeTab = 9
    }
  },
})
</script>


<style scoped>
.box {
  background-color: #2D2F3A;
  border-radius: 5px;
  padding: 15px;
}

.editor-container {
  color: white;
  min-height: 760px;
}

.editor-area {
  /* margin-right: 15px; */
  height: 100%;
}

.editor-view {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 91.35%;
}

.custom-editor-tab {
  color: lightgrey;
  padding: 13px;
  display: flex;
  align-items: center;
  gap: 15px;
  justify-content: space-around;
}

.custom-editor-tab-body {
  background-color: #343542;
  height: 93.2%;
  border-radius: 0 0 5px 5px;
  padding: 20px 15px;
}

.custom-editor-tab-body h3 {
  color: white;
  font-weight: 400;
}

.custom-tabs {
  color: lightgrey;
  display: flex;
  align-items: center;
  gap: 15px;
}

.custom-tabs .active {
  color: #e49c35;
  font-weight: 600;
}

.action-box {
  display: flex;
  align-items: center;
  gap: 40px;
}

.form-check {
  min-height: 0;
}

.active-text {
  color: #e49c35;
}

.box-navbar {
  background-color: #2E2F3A !important;
  border-radius: 5px;
}</style>